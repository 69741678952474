import * as React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import { Column, Columns } from "./styled-components"

// CAVEAT: if you add something here - you need to put it also in create-trip-page-plugin gatsby-node.js
// because TourCardFragment is not known during gatsby node inits :(
export const TourCardFragment = graphql`
    fragment TourCardFragment on  ContentfulTrip {
        title
        p:nameOfFotoliaPhotographer
        lengthInKm
        level {
            name
        }
        fields {
            yovelinoSlug
            mainPageExcerptHtml
        }
        mainPicture {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`

export interface ITourCardFragment {
  title: string;
  p: string;
  lengthInKm: number;
  level: {
    name: string;
  };
  fields: {
    yovelinoSlug: string,
    mainPageExcerptHtml: string
  };
  mainPicture: {
    localFile: {
      childImageSharp: {
        fluid: any;
      }
    }
  }
}

type Props = {
  tour: ITourCardFragment
}

const TourCard = ({ tour }: Props) => {
  return (
    <div key={tour.fields.yovelinoSlug} css={css`
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.2),0 1px 5px 0 rgba(0,0,0,0.12);
    `
    }>
      <div>
        <a href={tour.fields.yovelinoSlug} title={tour.title}>
          <figure css={css`
            position: relative;
            margin: 0;
          `}>
            <Img fluid={tour.mainPicture.localFile.childImageSharp.fluid}/>
            <div css={css`
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 50%;
              background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4));
            `}/>
            <div css={css`
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: ${rhythm(0.35)};
              padding-right: ${rhythm(0.25)};
              color: white;
            `}>&copy; {tour.p ? tour.p + " / Fotolia" : "yovelino"}
            </div>
            <figcaption css={css`
              position: absolute;
              color: white;
              font-size: ${rhythm(0.75)};
              bottom: ${rhythm(0.75)};
              padding-left: ${rhythm(0.25)};
            `}>
              {tour.title}
            </figcaption>
          </figure>
        </a>
      </div>
      <div css={css`
        padding: ${rhythm(0.25)};
        font-size: ${rhythm(0.5)};
      `}>
        <div dangerouslySetInnerHTML={{ __html: tour.fields.mainPageExcerptHtml }}/>
        <Columns>
          <Column>{tour.lengthInKm} km</Column>
          <Column css={css`
            text-align: right;
          `}>{tour.level.name}</Column>
        </Columns>
      </div>

    </div>
  )
}

export default React.memo(TourCard)
